var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// i18n
import './locales/i18n';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
// editor
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
// ----------------------------------------------------------------------
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// routes
import { Provider } from 'react-redux';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { MotionLazyContainer } from './components/animate';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import { AuthProvider } from './auth/JwtContext';
import { store } from './redux/store';
// ----------------------------------------------------------------------
export default function App() {
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(AuthProvider, { children: _jsx(HelmetProvider, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns }, { children: _jsx(SettingsProvider, { children: _jsxs(BrowserRouter, { children: [_jsx(ScrollToTop, {}), _jsx(MotionLazyContainer, { children: _jsx(ThemeProvider, { children: _jsx(ThemeSettings, { children: _jsx(ThemeLocalization, { children: _jsx(SnackbarProvider, { children: _jsx(Router, {}) }) }) }) }) })] }) }) })) }) }) })));
}
